import React, { useState } from 'react'
import confirm from 'antd/lib/modal/confirm';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { IoChevronBack, IoClose, IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import styled from 'styled-components';
import IconSwitcher from '@components/common/IconSwitcher';
import IconSwitcherTransac from '@components/common/IconSwitcherTransac';
import { CSS_HELPERS, MEDIA_SCREENS, THEME_COLORS, THEME_VARS } from '@theme/constants';
import { Badge, Col, Collapse, Drawer, Modal, Row } from 'antd';

const { Panel } = Collapse;

const MenuMovil = ({ data, toggleMenu, setToggleMenu }) => {
  const [subMenu, setSubMenu] = useState(false);
  const { replace } = useRouter();

  const handleConfirm = () => {
    confirm({
      content: <p>¿Estás seguro que deseas cerrar la sesión?</p>,
      style: { fontSize: '5px' },
      okButtonProps: { style: { borderRadius: '10px' } },
      cancelButtonProps: { style: { borderRadius: '10px' } },
      okText: "Sí, Salir",
      okType: "primary",
      cancelText: "No, Cancelar",
      onOk() {
        replace('/transacciones/logout');
      }
    })
  }



  const items = data.map(({ titulo, routes, slug }, i) => {
    if (slug) {
      return null;
    }

    return {
      key: i.toString(),
      label: titulo,
      children: routes.map(({ key, titulo, slug, icon }) => (
        <Col xs={24} key={key} className="menu_card">
          <Link className="panel menu_card" href={"/" + slug}>
            <span className="menu_card_inner" style={{ paddingLeft: '2rem' }}>
              <div className={`menu_card_icon ${icon === 'update' ? 'svgWhite' : ''}`}>
                <IconSwitcherTransac name={icon} />
              </div>
              <p className="md strong500 uppa">{titulo}</p>
            </span>
          </Link>
        </Col>
      )),
    };
  }).filter(item => item !== null); // Filt

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <>
      <MenuIcon onClick={() => setToggleMenu(true)}>
        <IoMenuOutline />
      </MenuIcon>

      <Drawer
        placement="right"
        className="custom_drawer"
        styles={{
          header: {
            display: 'none',
          },
          body: {
            background: THEME_COLORS.light.primary.alt + 'E8',
            padding: 0,
          },
          mask: {
            padding: 0,
            background: "#FFFFFF00",
          }
        }}
        onClose={() => setToggleMenu(false)}
        open={toggleMenu}
      >
        <View>
          <OpenClose onClick={() => setToggleMenu(false)} style={{ cursor: 'pointer' }}>
            <IconSwitcher name="contrast_logo" size={1.5} />
            <IoClose />
          </OpenClose>
          <Row>
            {data && data.length && data.map(({ titulo, slug, routes, leida, icon, key }, i) => (
              <Col xs={24}
                key={i}
                className="menu_card"
              >
                {
                  slug &&
                  <Link href={"/" + slug} className="menu_card_inner">
                    <div className="menu_card_icon" >
                      <IconSwitcherTransac name={icon} />
                    </div>
                    <p className="md strong500 uppa">
                      {titulo}
                    </p>
                  </Link>
                }
                {/* <Drawer
										headerStyle={{ display: 'none' }}
										maskStyle={{ padding: 0, background: "#FFFFFF00" }}
										bodyStyle={{ padding: 0, background: THEME_COLORS.light.primary.alt + 'E8' }}
										className="custom_drawer"
										open={subMenu === key}
										onCancel={() => setSubMenu(false)}

									>
										<View>
											<OpenClose onClick={() => setSubMenu(false)} style={{ cursor: 'pointer' }}>
												<span className='uppa strong600'>{titulo}</span>
												<IoClose />
											</OpenClose>
											{routes && routes.length ? routes.map(({ titulo, slug, routes }) =>
												<div key={slug} className="menu_card">
													<div className="menu_card_inner">
														<div className="menu_card_icon">
															<IconSwitcherTransac name={slug} />
														</div>
														<div className='md strong500 uppa'>
															<Link href={"/" + slug}>
																<a>{titulo}</a>
															</Link>
														</div>
														{(leida === false) ? <Badge status="error" /> : <></>}
													</div>
												</div>
											) : <></>}
										</View>

									</Drawer> */}
              </Col>
            ))}
            <Collapse
              items={items}
              accordion
              collapsible='header'
              defaultActiveKey={['0']}
              onChange={onChange}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                width: '100%',
                color: 'white',
              }}
            />
          </Row>
        </View>
      </Drawer>
    </>
  )
}

export default MenuMovil;



const View = styled.div`
    overflow:hidden;
    ${CSS_HELPERS.BOX_TAILWIND}
    overflow:hidden;
    height:100%;

    .ant-row {        
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
        padding: 0 30px ;
    }
    }
    .md.uppa {
        font-size: 15px;
    }

    .menu_card_icon {
        font-size: 13px;
    }

    .menu_card{
        a{
            width:100%;
        }
        cursor:pointer;
        display:flex;
        justify-content:center;
        line-height:0;

        .menu_card_inner{
            padding:1em;
            gap:1em;
            width:100%;
            display:flex;
            align-items:center;
            color:${THEME_COLORS.light.primary.contrastText};
            .menu_card_icon{
                svg{
                 font-size:1.8em;
                 color:${THEME_COLORS.light.primary.contrastText};
               }
            }
            :hover{
                background:${THEME_COLORS.light.primary.main};
            }
        }
    }

		.ant-collapse>.ant-collapse-item>.ant-collapse-header {
			color: white;
			border: none;
			font-size: 1.2rem;
			font-weight: 500;
		}

	}

	// panel styles
	.ant-collapse-content {
		background: ${THEME_COLORS.light.primary.alt};
		border: none;
	}
	
	.eeXVnr .menu_card .menu_card_inner{
		display:flex;
		:hover{
			background:${THEME_COLORS.light.primary.main};
		}
	}

	.ant-collapse-content>.ant-collapse-content-box{
		padding:0;
	}

	.svgWhite {
		svg {
			path{
				stroke: white;
			}
		}
	}
   
`;

const OpenClose = styled.div`
    padding:1em;
    display:flex;
    gap:1em;
    justify-content:space-between;
    color:white;
    align-items: center;
    padding: 20px;

    
    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
    padding: 20px 45px;
    }

    svg{
        color:white;
        font-size:2.1em;
    }
`

const MenuIcon = styled.div`
        border-radius:10px;
        background:white;
        display:flex;
        justify-content:center;
        align-items:center;
        cursor:pointer;
        ${CSS_HELPERS.TSCALE};
        svg{
            font-size:2.1em;
            color:${THEME_COLORS.light.primary.alt}
        }
`
