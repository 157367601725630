/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { useRouter } from "next/navigation";
import { useDispatch, useSelector } from '@redux/store';
import { useEffect, useState } from "react";
import { loginUsersMe, LogOutApp, updateResidenciaFiscal, validarResidenciaFiscal } from "@redux/slices/login";
import Spinner from "@components/Spinner";
import { Button, Form, Input, message, Modal, Radio, Result, Space } from "antd";
import styled from "styled-components";
import { changeValidateStatus } from "@redux/slices/otp";
import OTP from "./OTP";


const AuthProvider = ({ children }) => {
	const dispatch = useDispatch();
	const router = useRouter();
	const { error, user, ldap, loadingSession, residenciaFiscal, loadingRSF } = useSelector(state => state.login);
	const { validatedCode, isRunning } = useSelector(state => state.otp);
	const [modal2Open, setModal2Open] = useState(false);
	const [modalLastOpen, setModalLastOpen] = useState(false);
	const [intervalMinuteInaciveSession, setintervalMinuteInaciveSession] = useState(5);
	const [maxMinutesActiveSession, setMaxMinutesActiveSession] = useState(20);
	const [intervalMinuteInaciveSessionCounter, setintervalMinuteInaciveSessionCounter] = useState(0);
	const [showModalResidenciaFiscal, setshowModalResidenciaFiscal] = useState(false);
	const [formResidenciaFiscal] = Form.useForm();
	const [showRSFForm, setshowRSFForm] = useState(false);
	const [showDatosUpdate, setShowDatosUpdate] = useState(false);
	const [preguntasResidencia, setpreguntasResidencia] = useState([]);
	const [datosToUpdate, setDatosToUpdate] = useState();
	let timeoutID;

	useEffect(() => {
		if (!user) {
			dispatch(loginUsersMe());
		};
	}, [dispatch, user]);


	useEffect(() => {
		if (loadingSession) return;

		if (error && !user) {
			router.push('/auth/login');
		}

	}, [error, user]);


	useEffect(() => {
		startEvents();
		startTimer();

		// removing events when component unmount
		return () => {
			window.removeEventListener("mousemove", resetTimer);
			window.removeEventListener("mousedown", resetTimer);
			window.removeEventListener("keypress", resetTimer);
			window.removeEventListener("DOMMouseScroll", resetTimer);
			window.removeEventListener("mousewheel", resetTimer);
			window.removeEventListener("touchmove", resetTimer);
			window.removeEventListener("MSPointerMove", resetTimer);
		}
	}, [])



	useEffect(() => {
		if (user?.numero_identificacion) {
			setTimeout(() => {
				dispatch(validarResidenciaFiscal(user?.numero_identificacion, setshowModalResidenciaFiscal, setshowRSFForm, setShowDatosUpdate, setpreguntasResidencia));
			}, 1000);
		}
	}, [dispatch, user])


	useEffect(() => {
		if (residenciaFiscal) {
			formResidenciaFiscal.setFieldsValue(residenciaFiscal);
		}
	}, [formResidenciaFiscal, residenciaFiscal])


	useEffect(() => {
		if (validatedCode && datosToUpdate) {
			dispatch(updateResidenciaFiscal(datosToUpdate, setshowModalResidenciaFiscal));
			dispatch(changeValidateStatus(false));
		}
	}, [validatedCode]);


	const startEvents = () => {

		if (router?.asPath?.includes('/auth/login')) return;
		window.setTimeout(lastNotice, 60000 * (maxMinutesActiveSession - 1)); // iniciador último aviso
		window.setTimeout(() => {
			router.push('/transacciones/logout/');
		}, 60000 * maxMinutesActiveSession);

		window.addEventListener("mousemove", resetTimer, false);
		window.addEventListener("mousedown", resetTimer, false);
		window.addEventListener("keypress", resetTimer, false);
		window.addEventListener("DOMMouseScroll", resetTimer, false);
		window.addEventListener("mousewheel", resetTimer, false);
		window.addEventListener("touchmove", resetTimer, false);
		window.addEventListener("MSPointerMove", resetTimer, false);
	}

	const startTimer = () => {
		//if(intervalMinuteInaciveSessionCounter = maxRetryTimes - 2) return;
		timeoutID = window.setTimeout(goInactive, intervalMinuteInaciveSession * 60000); // iniciador de sesion activa
	}

	const resetTimer = (e) => {
		window.clearTimeout(timeoutID);
		goActive();
	}

	const goInactive = () => {
		setModal2Open(true);
	}

	const goActive = () => {
		startTimer();
	}

	const lastNotice = () => {
		setModalLastOpen(true);
	}

	const startLasMinuteSession = () => {
		timeoutID = window.setTimeout(cerrarSession, 1 * 60000); // iniciador último aviso
		setModalLastOpen(false);
	}

	const cerrarSession = () => {
		setModal2Open(false);
		setModalLastOpen(false);
		setshowModalResidenciaFiscal(false);
		dispatch(LogOutApp());
	}


	const onFinish = (values) => {
		const res = {
			...values,
			numero_identificacion: user?.numero_identificacion,
			actualizaDatos: showDatosUpdate,
			actualizaRSF: showRSFForm
		};
		setDatosToUpdate(res);

		dispatch(updateResidenciaFiscal(res, setshowModalResidenciaFiscal));

		// showDatosUpdate
		// 	? dispatch(getOTPCode(user?.numero_identificacion))
		// 	: dispatch(updateResidenciaFiscal(res, setshowModalResidenciaFiscal))
	};

	const onFinishFailed = (errorInfo) => {
		//console.log('Failed:', errorInfo);
	};

	const formatToNumber = (e) => {
		let text = e.target.value;
		let textoLimpio = text.replace(/[^0-9]/g, '');
		formResidenciaFiscal.setFieldsValue({ celular: textoLimpio });
	};


	return (
		<>
			{
				!user || !ldap
					? <div className="flex-center" style={{ height: '98vh' }}><Spinner /></div>
					: children
			}

			<View>
				<Modal
					centered
					className="modal_session"
					open={modal2Open}
				>
					<Result
						status="info"
						title="¿Necesitas más tiempo?"
						subTitle="Has permanecido inactivo y por tu seguridad cerraremos automáticamente tu sesión."
						extra={[
							<Button
								type="primary"
								key="console"
								onClick={() => {
									setintervalMinuteInaciveSessionCounter(intervalMinuteInaciveSessionCounter + 1);
									goActive();
									setModal2Open(false);
								}}
							>
								Continuar
							</Button>,
							<Button
								key="buy"
								onClick={() => dispatch(LogOutApp())}
							>Cerrar Sesión
							</Button>,
						]}
					/>
				</Modal>


				<Modal
					centered
					className="modal_session"
					open={modalLastOpen}
				>
					<Result
						status="info"
						title="Te queda sólo 1 minuto."
						subTitle="Asegúrate de terminar tus transacciones o puedes volver a iniciar sesión y continuar."
						extra={[
							<Button
								type="primary"
								key="console"
								onClick={() => startLasMinuteSession()}
							>
								Continuar
							</Button>,
							<Button
								key="buy"
								onClick={() => dispatch(LogOutApp())}
							>Cerrar Sesión
							</Button>,
						]}
					/>
				</Modal>

				<Modal
					centered
					className="modal_session modal_residencia"
					getContainer={false}
					open={showModalResidenciaFiscal && !isRunning}
					footer={null}
				>
					<Form
						name="form_residencia_fiscal"
						form={formResidenciaFiscal}
						layout="vertical"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<ViewResidencia>
							{showDatosUpdate && (
								<>
									<p>Si deseas transaccionar, debes registrar y/o confirmar los siguientes datos para mantenerte informado(a) sobre tus Fondos y la Administradora:</p>
									<br />
									<Form.Item
										label="Email:"
										name="mail"
										rules={[
											{ required: true, message: 'Para continuar, debes completar todos los campos requeridos.' },
											{ type: 'email', message: 'Por favor ingresa un email válido.' }
										]}
									>
										<Input readOnly={!showDatosUpdate} />
									</Form.Item>

									<Form.Item
										label="Celular:"
										name="celular"
										rules={[
											{ required: true, message: 'Para continuar, debes completar todos los campos requeridos.' },
											{ max: 10, min: 10, message: 'Por favor ingresa un celular válido.' }
										]}
									>
										<Input readOnly={!showDatosUpdate} type="text" onChange={formatToNumber} />
									</Form.Item>
								</>
							)}

							<br />

							{showRSFForm && (
								<>
									<p className="bold">Por favor contesta las preguntas de Residencia Fiscal*: </p>

									<Space className="space-residencia-fiscal" direction="vertical">

										{
											preguntasResidencia.map((pregunta, index) => (
												<>
													<Form.Item
														name={`pregunta${pregunta.codigo}`}
														label={`${pregunta.codigo}.- ${pregunta.contenido}`}
														rules={[{ required: true, message: 'Para continuar, debes completar todos los campos requeridos.' }]}
													>
														<Radio.Group>
															<Radio value="SI">Si</Radio>
															<Radio value="NO">No</Radio>
														</Radio.Group>
													</Form.Item>
												</>
											))
										}

										<p><span className="bold">Nota:</span> En caso de responder SI, a la pregunta 1 y/o 2, te llegará un correo con los formularios a llenar y un Oficial se pondrá en contacto contigo.</p>
									</Space>

									<p className="bold">*Residencia Fiscal se refiere a que si cuentas con obligaciones tributarias en otro país distinto de Ecuador.</p>
									<br />
								</>
							)}


							<Form.Item>
								<div className="flex-between gap-10">
									<Button type="primary" onClick={() => cerrarSession()} danger>
										Salir
									</Button>
									<Button loading={loadingRSF} type="primary" htmlType="submit">
										Aceptar
									</Button>
								</div>
							</Form.Item>
						</ViewResidencia>
					</Form>
				</Modal>

				<OTP />
			</View>
		</>
	)
}


const View = styled.div`
	.modal_session .ant-modal-footer,
	.modal_otp .ant-modal-footer,
	.modal_residencia .ant-modal-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.modal_otp .ant-form-item-control-input-content,
	.modal_residencia .ant-form-item-control-input-content {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.modal_otp .ant-modal-content .ant-modal-close,
	.modal_residencia .ant-modal-content .ant-modal-close {
		display: none;
	}
`;


const ViewResidencia = styled.div`
	p {
		font-size:13px;
	}

	.ant-form-item-explain-error {
		color: #fd8485;
    	text-align: center;
    	//outline: double;
    	border-radius: 10px;
	}

`

export default AuthProvider