import { AiOutlineBell, AiOutlineHome, AiOutlineLogout, AiOutlineUser } from "react-icons/ai";
import { BiLineChart, BiPlanet } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { RiCustomerService2Line } from "react-icons/ri";
import { MdOutlinePersonalInjury, MdOutlineSupportAgent } from "react-icons/md";
import { TbUsers } from "react-icons/tb";
import { GrPowerCycle } from "react-icons/gr";

export const iconKeys = {
    "inicio": <AiOutlineHome />,
    "incremento": <BsCashCoin />,
    "notificaciones": <AiOutlineBell />,

    "usuario": <AiOutlineUser />,
    "perfil": <AiOutlineUser />,
    "logout": <AiOutlineLogout />,

    "asistencia": <RiCustomerService2Line />,
    "siniestros": <MdOutlinePersonalInjury />,
    "solicitudes": <MdOutlineSupportAgent />,
    "web": <BiPlanet />,

    "beneficios": <BiLineChart />,
    "globalSalud": <TbUsers />,

    'update': <GrPowerCycle />
}

const IconSwitcherTransac = ({ name, className }) => {
    if (!name) return <h1>No icon</h1>
    return (<div
        className={className}
    >
        {iconKeys[name]}
    </div>
    )
};

export default IconSwitcherTransac;