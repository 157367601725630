import { resetState, solicitarOTP, validarOTP } from "@redux/slices/otp";
import { useDispatch, useSelector } from "@redux/store";
import { Button, Divider, Form, Input, Modal, notification } from "antd";
import { useState } from "react";
import styled from "styled-components";

const OTP = () => {
    const [formOTP] = Form.useForm();
    const dispatch = useDispatch();
    const { showModal, email, celular, loading } = useSelector(state => state.otp);
    const { user } = useSelector(state => state.login);
    const [isMail, setIsMail] = useState(false);
    const [stepper, setstepper] = useState(0);
    const [reload, setReload] = useState({});

    const emailTrimCounter = email && email?.split(' ').length - 1 || '';
    const celularTrimCounter = celular && celular?.split(' ').length - 1 || '';

    const onFinish = (values) => {
        let code = values.otpCode1 + values.otpCode2 + values.otpCode3 + values.otpCode4 + values.otpCode5 + values.otpCode6;

        dispatch(validarOTP(user.numero_identificacion, code, closeModal))
    };

    const onFinishFailed = (errorInfo) => {
        notification.warning({
            message: '¡Aviso!',
            description: 'Por favor ingresa el código OTP.',
        });
    };


    const handleMedio = (e) => {
        const isEmail = e.target.innerText.includes('@');
        const canal = isEmail ? 'F5' : 'G5';
        setIsMail(isEmail);
        dispatch(solicitarOTP(user.numero_identificacion, canal, handleStepper));
    }


    const closeModal = () => {
        dispatch(resetState());
        formOTP.resetFields();
        setstepper(0);
    }


    const resendOTPCode = () => {
        formOTP.resetFields();
        setstepper(0);
    }


    function enmascararString(texto) {
        if (texto.length <= 6) {
            return texto;
        } else {
            const primerosDos = texto.slice(0, 2);
            const ultimosDos = texto.slice(-2);
            const enmascarado = primerosDos + "*".repeat(texto.length - 6) + ultimosDos;
            return enmascarado;
        }
    }

    const enmascararCorreo = (mail) => {
        let user = null;
        let domain = null;

        if (mail) {
            user = mail.split('@')[0];
            domain = mail.split('@')[1];

            if (user.length >= 5) {
                const primerosDos = user.slice(0, 2);
                const ultimosDos = user.slice(-2);
                let enmascarado = primerosDos + "*".repeat(user.length - 5) + ultimosDos;
                enmascarado = enmascarado + '@' + domain;

                return enmascarado;
            }
        }
        return mail;
    }

    const handleStepper = (id) => {
        setstepper(id ? id : stepper + 1)
    }

    const validateInputValue = (e) => {

        // Verifica si la tecla presionada es un dígito del 0 al 9
        const isDigit = /^[0-9]$/.test(e.key);
        const id = e && e.target.id;
        const name = id && id.split('_')[1];

        // Si la tecla no es un dígito, evita que se ingrese
        if (!isDigit && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
            e.preventDefault();
        }

        if (e.key === 'ArrowLeft') {
            const prevID = name && Number(name.split('otpCode')[1]) - 1;
            prevID > 0 && formOTP.getFieldInstance(`otpCode${prevID}`).focus();
        }

        if (e.key === 'ArrowRight') {
            const nextID = name && Number(name.split('otpCode')[1]) + 1;
            nextID < 7 && formOTP.getFieldInstance(`otpCode${nextID}`).focus();
        }
    };

    const handleChangeInput = (e) => {
        const id = e && e.target.id;
        const name = id && id.split('_')[1];
        const nextID = name && Number(name.split('otpCode')[1]) + 1;
        e.target.value && nextID < 7 && formOTP.getFieldInstance(`otpCode${nextID}`).focus();
    }

    return (
        <Modal
            //title="Volver"
            centered
            open={showModal}
            className="modal_session modal_residencia modal_otp"
            onCancel={closeModal}
            maskClosable={false}
        >
            <Form
                name="formOTP"
                form={formOTP}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onFieldsChange={(values) => {
                    setReload(values);
                }}
                autoComplete="off"
            >
                <ViewOTP>
                    {stepper == 0 &&
                        <>
                            <br />
                            <div className="flex-center"><img src="/img/lock.png" alt="" /></div>
                            <br />
                            <h2 className="flex-center fz-20 mb-10 f-normal">
                                Para concluir tu transacción, debes ingresar el {<br />} código OTP.
                            </h2>
                            <h3 className="flex-center mb0 f-normal">¿Por qué medio deseas recibirlo?</h3>
                            <p className="flex-center f-normal">Recomendamos que envíes el código OTP a tu celular</p>
                            <br />
                            {email && emailTrimCounter < 1 && <Button type="default" onClick={handleMedio}>{enmascararCorreo(email)}</Button>}
                            {celular && celularTrimCounter < 1 && <Button type="default" onClick={handleMedio}>{enmascararString(celular)}</Button>}
                        </>
                    }


                    {
                        stepper == 1 &&
                        <>
                            <div className="flex-center"><img src="/img/lock.png" alt="" /></div>
                            <br />
                            <h2 className="flex-center">Ingresa tu código OTP.</h2>
                            <p className="flex-center">El código fue enviado a tu {isMail ? 'correo' : 'celular'}</p>
                            <Divider />

                            <div className="otp-container">
                                <Form.Item
                                    name="otpCode1"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        }
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>

                                <Form.Item
                                    name="otpCode2"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        },
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>

                                <Form.Item
                                    name="otpCode3"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        },
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>

                                <div className="flex-center bold">-</div>

                                <Form.Item
                                    name="otpCode4"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        },
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>

                                <Form.Item
                                    name="otpCode5"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        },
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>

                                <Form.Item
                                    name="otpCode6"
                                    onKeyDown={validateInputValue}
                                    onChange={handleChangeInput}
                                    rules={[
                                        {
                                            required: true,
                                            len: 1,
                                            min: 0
                                        },
                                    ]}
                                >
                                    <Input type="text" className="otp-input" maxLength={1} />
                                </Form.Item>
                            </div>

                            <Divider />
                            <Form.Item>
                                <Button
                                    shape="round"
                                    loading={loading}
                                    disabled={!formOTP.getFieldValue('otpCode1') || !formOTP.getFieldValue('otpCode2') || !formOTP.getFieldValue('otpCode3') || !formOTP.getFieldValue('otpCode4') || !formOTP.getFieldValue('otpCode5') || !formOTP.getFieldValue('otpCode6')}
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    className="login-form-button"
                                    style={{ width: '60%', backgroundColor: formOTP.getFieldValue('otpCode1') && formOTP.getFieldValue('otpCode2') && formOTP.getFieldValue('otpCode3') && formOTP.getFieldValue('otpCode4') && formOTP.getFieldValue('otpCode5') && formOTP.getFieldValue('otpCode6') ? '#31af91' : null }}
                                >
                                    Continuar
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    shape="round"
                                    block
                                    className="login-form-button"
                                    style={{ width: '60%' }}
                                    onClick={resendOTPCode}
                                >
                                    Reenviar código
                                </Button>
                            </Form.Item>
                        </>
                    }

                    <br />
                    <div className="flex-center img-footer"><img src="/img/metlife.png" alt="metlife" /></div>
                    <br />


                </ViewOTP>
            </Form >
        </Modal >
    )
}



const ViewOTP = styled.div`

    /* Estilos para el contenedor principal */
    .otp-container {
    display: flex;
    justify-content: center;
    }

    /* Estilos para cada uno de los campos de entrada */
    .otp-input {
    width: 45px; /* Ajusta el ancho según tus necesidades */
    height: 45px; /* Ajusta la altura según tus necesidades */
    text-align: center;
    font-size: 24px; /* Ajusta el tamaño de fuente según tus necesidades */
    margin: 0 5px; /* Ajusta el margen según tus necesidades */
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
    }

    /* Estilo para resaltar el campo activo */
    .otp-input:focus {
    outline: none;
    border-color: #31af91; /* Ajusta el color de resaltado según tus necesidades */
    }

    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Estilo de la animación */
    @keyframes cursor-blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .otp-input::placeholder {
        animation: cursor-blink 1s infinite;
    }

    .ant-divider {
        margin: 15px 0;
    }

    button {
        width: 60%;
        margin: auto;
        border:0;
        margin-bottom: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }

	p {
		font-size:13px;
        color: #646464;
	}

    img {
        width: 70px;
        
    }

    h2 {
        text-align: center;
        line-height: 1.2;
        margin: 0;
    }

    .img-footer img {
        width: 150px ;
    }

	.ant-form-item-explain-error {
		display: none;
	}

    .ant-result {
        padding: 35px;
    }

`


export default OTP