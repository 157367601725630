import { useEffect, useRef } from "react";
import { Avatar, Divider, List, notification, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import { getNotificaciones } from "@redux/slices/usuariosNotificaciones";
import { startLeerNotificacion } from "@redux/slices/usuariosNotificaciones";
import { THEME_COLORS } from "@theme/constants";


export const ListNotificaciones = ({ setNotificationsVisible }) => {
  const { user } = useSelector(state => state.login);
  const { notificaciones, totalNotificaciones } = useSelector(state => state.usuarioNotificaciones);
  const numeroNotificaciones = useRef(4);

  const dispatch = useDispatch();

  const cargarMasNotificaciones = () => {
    if (notificaciones.length >= 20) return;
    if (!user || !user.fecha_nacimiento) return;
    //console.log('cargar mas notificaciones')
    let di;
    const lastDateUpdated = user.fecha_modifica.split(' ')[0].replaceAll('/', '-');
    user.numero_identificacion
      ? di = user.numero_identificacion
      : di = user.ruc;

    numeroNotificaciones.current += 4;

    dispatch(getNotificaciones({ fechaNacimiento: user.fecha_nacimiento, cantidad: numeroNotificaciones.current, uid: di, lastDateUpdated }));
  }

  useEffect(() => {
    if (!notificaciones?.length) return;

    // * CODIGO PARA MOSTRAR MODALES DE NOTIFICACIONES
    // controlar que se vuelva a ejecutar cuando se cargue una nueva notificacion
    // if (!!notificacionesModales.current.length) return;

    // Crear un array con los modals de notificaciones que se deben mostrar
    // notificaciones.forEach((notificacion) => {
    //   if ((notificacion.grupo_envio === 'actualizarDatos' || notificacion.grupo_envio === 'cumpleanos') && !notificacion.leida) {
    //     notificacionesModales.current = [{ notificacion, visible: true }, ...notificacionesModales.current];
    //     dispatch(startLeerNotificacion(notificacion.id));
    //   };
    // });

    // setShowModals(notificacionesModales.current);
    // eslint-disable-next-line
  }, [notificaciones]);

  const handleClickNotification = (item) => {
    notification.open({
      message: item.titulo,
      description: item.descripcion,
    });
    if (!item.leida) {
      // setNoLeida(false);
      dispatch(startLeerNotificacion(item.id));
    }
  }

  return (
    <>
      {
        !notificaciones.length ?
          <div id='notificaciones' style={{ height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: 8 }}>
            <p style={{ fontSize: '1rem' }}>No Tiene Notificaciones...</p>
          </div>
          :
          <div id='notificaciones' style={{ width: '300px', overflow: 'auto', maxHeight: '300px' }}>
            <InfiniteScroll
              style={{ backgroundColor: '#fff' }}
              dataLength={notificaciones.length}
              next={cargarMasNotificaciones}
              hasMore={(totalNotificaciones !== notificaciones.length) && notificaciones.length < 20}
              loader={<Skeleton style={{ padding: 5 }} avatar active paragraph={{ rows: 1, width: '100%' }} />}
              endMessage={<Divider plain>No hay más Notificaciones</Divider>}
              scrollableTarget={'notificaciones'}
            >
              <List
                style={{ backgroundColor: '#fff' }}
                loading={!notificaciones?.length}
                itemLayout="horizontal"
                dataSource={notificaciones}
                renderItem={item => (
                  <ListItem onClick={() => {
                    setNotificationsVisible(false);
                    handleClickNotification(item)
                  }}
                    key={item.id}
                    style={{ position: 'relative' }}
                  >
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar
                          src={item.imagen}
                          alt='no-image'
                        />}
                        title={item.titulo}
                        description={<p
                          className='descripcion-notificacion'
                          style={{
                            color: 'gray',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            maxWidth: '150px',
                          }}
                        >{item.descripcion}</p>}
                      />

                      {
                        !item.leida &&
                        <div style={{
                          borderRadius: '50%',
                          backgroundColor: `${THEME_COLORS.light.primary.main}`,
                          height: '10px',
                          width: 10,
                          position: 'absolute',
                          top: '50px',
                          right: '15px'
                        }} />
                      }
                    </List.Item>
                  </ListItem>
                )}
              />
            </InfiniteScroll>
          </div>
      }
    </>
  )
};

const ListItem = styled.div`
    cursor: pointer;
    max-height: 100px;
    textOverflow: ellipsis;
    whiteSpace: nowrap;
    overflow: hidden;
    padding: 5px 10px;
    &:hover {
        background-color: #f5f5f5;
    }

    .descripcion-notificacion {
        font-size: 12px !important;
    }
`
