import { useState } from 'react'
import styled from 'styled-components';
import Link from 'next/link';
import { Badge, Button, Dropdown, Menu } from 'antd';
import { ListNotificaciones } from './ListNotificaciones';
import { useRouter, usePathname } from 'next/navigation';
import confirm from 'antd/lib/modal/confirm';
import { MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import BoxUi from '@components/common/BoxUi';
import IconSwitcherTransac from '@components/common/IconSwitcherTransac';

const MenuOverlay = (routes) => {
	const rutas = routes?.filter(({ key }) => !key);
	if (rutas?.length) rutas.length = 1;
	const items = !rutas?.length ? routes : rutas;
	if (!items.length) return <ListNotificaciones />;
	return items.map(({ slug, titulo, icon, key }) => ({
		key: key,
		label: key ? (
			<Link href={"/" + slug} passHref>
				<MenuOverlayItem>
					{icon ? <IconSwitcherTransac className={'fz-10'} name={icon} /> : <></>}
					<p className="md">{titulo}</p>
				</MenuOverlayItem>
			</Link>
		) : (
			<ListNotificaciones />
		),
	}));
};

const MenuBar = ({ data }) => {
	const [subMenu, setSubMenu] = useState(false);
	const { replace } = useRouter();
	const asPath = usePathname();

	const handleConfirm = () => {
		confirm({
			content: <p>¿Estás seguro que deseas cerrar la sesión?</p>,
			style: { fontSize: '5px' },
			okButtonProps: { style: { borderRadius: '10px' } },
			cancelButtonProps: { style: { borderRadius: '10px' } },
			okText: "Sí, Salir",
			okType: "primary",
			cancelText: "No, Cancelar",
			onOk() {
				replace('/transacciones/logout');
			}
		})
	}

	return (
		<BoxUi noppadingX bg={THEME_COLORS.light.primary.main}>
			<View>
				{data.map(({ slug, titulo, routes, leida, icon, key }, i) => (
					slug ? (
						slug === 'transacciones/logout' ? (
							<div key={i} className='horizontal_menu_item' onClick={handleConfirm} role='button' tabIndex={0}
								aria-pressed="false">
								<div className="menu_card_inner"
									style={{
										padding: '20px 15px',
										backgroundColor: asPath === `/${slug}/` ? '#125695' : asPath.includes(slug) && slug !== 'transacciones' ? '#125695' : 'transparent'
									}}
								>
									<div className="menu_card_icon">
										<IconSwitcherTransac name={icon} />
									</div>
									<p className="md strong500 uppa">
										{titulo}
									</p>
								</div>
							</div>
						) : (
							<Link key={i} href={"/" + slug} className="horizontal_menu_item">
								<div className="menu_card_inner"
									style={{
										padding: '20px 15px',
										backgroundColor: asPath === `/${slug}/` ? '#125695' : asPath?.includes(slug) && slug !== 'transacciones' ? '#125695' : 'transparent'
									}}
								>
									<div className="menu_card_icon">
										<IconSwitcherTransac name={icon} />
									</div>
									<p className="md strong500 uppa">
										{titulo}
									</p>
								</div>
							</Link>
						)
					) : (
						<Dropdown
							key={i}
							menu={{ items: MenuOverlay(routes) }}
							placement="bottom"
						>
							<div className="horizontal_menu_item" onClick={() => setSubMenu(slug)} role='button' tabIndex={0}
								aria-pressed="false">
								<div className="menu_card_inner" style={{ padding: '20px 15px' }}>
									<div className="menu_card_icon">
										<Badge dot={leida === false}>
											<IconSwitcherTransac name={icon} />
										</Badge>
									</div>
									<p className="md strong500 uppa">{titulo}</p>
								</div>
							</div>
						</Dropdown>
					)
				))}
			</View>
		</BoxUi>
	)
}

export default MenuBar;


const View = styled.div`
    @media screen and  (min-width: ${0}px) and (max-width: ${MEDIA_SCREENS.SM}px) { 
        display:none;
    }
        display:flex;
        flex-direction:row;
        // padding:5px;
        justify-content: flex-end;


        .md.uppa {
            font-size:14px;
        }
        .horizontal_menu_item{
            font-size: 12px;
            &:hover {
                cursor: pointer;
                background-color: #125695;
            }

          .menu_card_inner{
            display:flex;
            align-items:center;
            line-height:0;
            height:3em;
            padding:0 1em;
            gap:0.8em;
            cursor:pointer;
            color:white;
            svg{
                color:white;
                font-size:1.5em;
            }
          }
        }
`;

const MenuOverlayItem = styled.div`
    padding: 0.5em 0.5em;
    display:flex;
    gap:.8em;
    svg{
        font-size:1.5em;
    }

    &:hover {
        background-color: red !important;
    }

	li.ant-dropdown-menu-item-active {
		background-color: red !important;
	}
`
