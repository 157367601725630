"use client";

import { useEffect, useRef, useState } from 'react';
import { Dropdown, Row, Typography, Modal } from 'antd';
import Head from 'next/head';
import styled from 'styled-components';
import { BiUser } from 'react-icons/bi';
import Footer from '@layouts/common/Footer';
import { useDispatch, useSelector } from '@redux/store';
import MenuBar from '@layouts/common/MenuBar';
import { setMenu } from '@redux/slices/login';
import MenuMovil from '@layouts/common/MenuMovil';
import { getNotificaciones } from '@redux/slices/usuariosNotificaciones';
import { MEDIA_SCREENS, THEME_COLORS } from '@theme/constants';
import AuthProvider from '@components/auth/AuthProvider';
import { ListNotificaciones } from '@layouts/common/ListNotificaciones';
import IconSwitcher from '@components/common/IconSwitcher';
import Link from 'next/link';
import { useRouter } from 'next/navigation';


const TransacLayout = ({ children }) => {
	const dispatch = useDispatch();
	const [toggleMenu, setToggleMenu] = useState(false);
	const [notificationsVisible, setNotificationsVisible] = useState(false);
	const [screenWidth, setScreenWidth] = useState();
	const { replace, push } = useRouter();

	// TODO: QUE PASO AQUI
	const numeroNotificaciones = useRef(4);

	//notificaciones
	const [noLeida, setNoLeida] = useState(false);

	//sesion
	const { user, routes } = useSelector(state => state.login);
	const { notificaciones } = useSelector(state => state.usuarioNotificaciones);

	// //Carga notificaciones 
	useEffect(() => {
		if (user?.fecha_modifica) {
			dispatch(getNotificaciones({
				fechaNacimiento: user.fecha_nacimiento,
				cantidad: numeroNotificaciones.current,
				uid: user.numero_identificacion ? user.numero_identificacion : user.ruc,
				lastDateUpdated: user.fecha_modifica.split(' ')[0].replaceAll('/', '-')
			}));
		}

	}, [dispatch, user]);

	//Si las notificaciones fueron cargadas
	useEffect(() => {
		if (!notificaciones?.length) {
			dispatch(setMenu({ notificaciones: [] }));
			return;
		};

		//Carga de notificaciones en el menu
		dispatch(setMenu({ notificaciones }));

		// ver si existe una notificacion no leida
		setNoLeida(notificaciones.some(({ leida }) => !leida));
	}, [notificaciones, dispatch]);

	let name;
	if (user?.primer_nombre || user?.primer_apellido) {
		name = `${user.primer_nombre} ${user.primer_apellido}`;
	}

	if (user?.ruc) {
		name = user.nombreEmpresa;
	}


	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		setScreenWidth(window.innerWidth);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const handleConfirm = () => {
		Modal.confirm({
			content: <p>¿Estás seguro que deseas cerrar la sesión?</p>,
			style: { fontSize: '5px' },
			okButtonProps: { style: { borderRadius: '10px' } },
			cancelButtonProps: { style: { borderRadius: '10px' } },
			okText: "Sí, Salir",
			okType: "primary",
			cancelText: "No, Cancelar",
			onOk() {
				replace('/transacciones/logout');
			}
		})
	}

	const items = [
		{
			key: '1',
			label: (
				<div onClick={() => push('/transacciones/password')} role='button' tabIndex={0} aria-pressed="false">
					<MenuItem style={{ fontSize: 16, textAlign: 'right' }}>
						Cambiar Contraseña
					</MenuItem>
				</div>
			),
		},
		{
			key: '2',
			label: (
				<div
					style={{ width: '100%' }}
					onClick={() => {
						handleConfirm();
					}}
					role='button'
					tabIndex={0}
					aria-pressed="false"
				>
					<MenuItem style={{ fontSize: 16, textAlign: 'right' }}>
						Cerrar Sesión
					</MenuItem>
				</div>
			),
		}
	];

	return (
		<AuthProvider>
			<View className='transac-layout'>
				<Head>
					<title> GENESIS | Administradora de Fondos</title>
				</Head>

				<MainBar>

					<Link
						href={"/transacciones"}
						passHref
					>
						<div className="logo-genesis">
							<IconSwitcher name="logo" size={2.5} />
						</div>
					</Link>

					<Row className='userOptions' align='middle'>
						<Dropdown
							menu={{
								items
							}}
						>
							<Row
								style={{
									cursor: 'pointer',
									alignItems: 'center'
								}}
							>
								<BiUser fontSize={20} style={{ marginRight: 5 }} />
								{
									screenWidth > MEDIA_SCREENS.SM &&

									<MenuItem><Typography.Text className='userName'>HOLA, {name}</Typography.Text></MenuItem>
								}
							</Row>
						</Dropdown>

						{/* HABILITAR NOTIFICACIONES */}
						{/* <Dropdown
							overlay={<ListNotificaciones setNotificationsVisible={setNotificationsVisible} />}
							placement='bottomRight'
							trigger={['click']}
							overlayStyle={{ backgroundColor: 'white' }}
							arrow
							visible={notificationsVisible}
						>
							<Badge
								dot={noLeida}
							>
								<BiBell
									fontSize={22}
									style={{ cursor: 'pointer' }}
									onClick={() => setNotificationsVisible(!notificationsVisible)}
								/>
							</Badge>
						</Dropdown>
						<div className='overlay-notifiaciones'
							style={{
								display: notificationsVisible ? 'block' : 'none',
							}}
							onClick={() => setNotificationsVisible(false)}
						/> */}
						{routes && routes.length ? <MenuMovil data={routes} toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} /> : <></>}
					</Row>
				</MainBar>

				<MenuBar data={routes} />
				<Container isEmptyRoutes={routes?.length === 0}>
					{/* <div className='flex-center'><h1 >HOLA</h1></div> */}
					{children}
				</Container>
				<Footer />
			</View>
		</AuthProvider>
	)
}

export default TransacLayout;


const View = styled.div`
    height:100%;
	display: grid;
    grid-template-rows: 69px 40px 1fr 75px;
`

const Container = styled.div`
    background:${THEME_COLORS.light.bg.main};
	 height: 100%;
	 overflow: auto;

    ${props => props.isEmptyRoutes && "height: calc(100% - 12em);"}

    @media screen and (max-width: ${MEDIA_SCREENS.SM}px) {
        /* height: calc(100% - 12em); */
    }
`

const MainBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 1rem;
	
	@media (min-width: ${MEDIA_SCREENS.SM}px) {
		padding: 0 4rem;
	}

	.userOptions {
		gap: 2rem;

		@media (max-width: ${MEDIA_SCREENS.SM}px) {
			gap: 1rem;
		}
	}

	.userName {
		font-size: 15px;
		font-weight: 700;
		display: none;

		@media (min-width: ${MEDIA_SCREENS.XS}px) {
			display: block;
		}
	}

	.notificaciones {
		position: absolute;
		background-color: white;
		z-index: 41;
		top: 17px;
		right: -50px;
		
		@media (min-width: ${MEDIA_SCREENS.XS}px) {
			top: 15px;
			right: 10px;
		}
	}

	.overlay-notifiaciones{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 40;
	}

`;

const MenuItem = styled.p`
	font-size: 16px;
	text-align: right;
	padding: 0.5rem 0.5rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	
	&::after {
		content: '';
		display: block;
		width: 0;
		margin-left: auto;
		height: 3px;
		background: ${THEME_COLORS.light.secondary.main};
		transition: width 0.3s;
	}

	&:hover::after {
		width: 100%;
		transition: width 0.3s;
	}
`;
